import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from '../context/Context';
import '../styles/EditarProducto.css'
import {ProductAPI,ProductoEditar,Product,RespuestaAPI} from '../types/interfaces';

const EditarProducto: React.FC = () => {
  const {APIContext}=React.useContext(Context);
  const {consultaProductos,validarToken,isAuthenticated,APIProductos}=APIContext;
  const [filtro, setFiltro] = useState<string>("");
  const [productoSeleccionado, setProductoSeleccionado] = useState<Product | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [nombre, setNombre] = useState<string>("");
  const [descripcion, setDescripcion] = useState<string>("");
  const [precioVenta, setPrecioVenta] = useState<number>(0);
  const [dadoDeBaja, setDadoDeBaja] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchProducts = async () => {
    try {
      consultaProductos()
        .then((data:RespuestaAPI) =>{
          if (data.status === 'ok') {
            // console.log(data)
            const productosAPI: ProductAPI[] = data.result.Products
            const tempProducts: Product[] = [];

            for (let d of productosAPI) {
              const hola: Product = {
                id: parseInt(d.ID_Producto),
                name: d.Nombre_Producto,
                description: d.Descripcion,
                price: parseFloat(d.Precio_Venta),
                stock: parseInt(d.Stock_Actual),
                quantity: 0
              }
              tempProducts.push(hola);
            }
            setProducts([...tempProducts]);
            // console.log(products)
          }
        });

    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    // Aquí puedes realizar una llamada a tu backend para obtener la lista de productos
    // y luego actualizar el estado "products" con los datos recibidos
    try{
      // const respuesta:RespuestaAPI =await validarToken()
      validarToken()
      // await new Promise((resolve) => setTimeout(resolve, 5000));
        // console.log(respuesta)
        if(!isAuthenticated){
          navigate('/');
        }else{
          fetchProducts();
        }
      
    }
    catch(e){
      console.log(e)
    }
    
  }, [isAuthenticated]);
  const handleFiltroChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiltro(e.target.value);
    setProductoSeleccionado(null);
  };

  const handleProductoSeleccionado = (producto: Product) => {
    setProductoSeleccionado(producto);
    setNombre(producto.name);
    setDescripcion(producto.description);
    setPrecioVenta(producto.price);
    setDadoDeBaja(false);
  };

  const handleEditarProducto = async () => {
    if (productoSeleccionado) {
      try {
        let state:string='Activo'
        if(dadoDeBaja){
          state='Inactivo';
        }
        const productoEditar :ProductoEditar=
        {
          Estado:"EditarProducto",
          Nombre_Producto:nombre,
          Descripcion:descripcion,
          Precio_Venta:precioVenta,
          State:state,
          ID_Producto:productoSeleccionado.id
        }
        APIProductos(productoEditar).then((data:RespuestaAPI)=>{
          if(data.status==="ok"){
            alert("Producto editado exitosamente");
            setProductoSeleccionado(null);
            fetchProducts();
          }
        })
        
      } catch (error) {
        console.error("Error fetching products:", error);
      }
      // Aquí deberías realizar la lógica para editar el producto en tu BD
      // Puedes utilizar los valores de los estados nombre, descripcion, precioVenta y dadoDeBaja

      // Una vez que se haya realizado la edición, puedes mostrar una alerta o realizar alguna otra acción
    }
  };

  return (
    <div className="contenedor">
      <section className="columna columna-30">
      <h2>Editar Producto</h2>
      <div>
        <label htmlFor="filtroInput">Filtro:</label>
        <input type="text" id="filtroInput" value={filtro} onChange={handleFiltroChange} />
      </div>
      <ul>
        {products
          .filter((producto) => producto.name.toLowerCase().includes(filtro.toLowerCase()))
          .map((producto) => (
            <li key={producto.id} 
            className="productoItem"
            onClick={() => handleProductoSeleccionado(producto)}>
              {producto.name}
            </li>
          ))}
      </ul>
      </section>
      
      {productoSeleccionado && (
        <form className="columna columna-70 form-container" >
          <h3>Editar Producto: {productoSeleccionado.name}</h3>
          <div>
            <label htmlFor="nombreInput">Nombre:</label>
            <input
              type="text"
              id="nombreInput"
              className="inputField"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="descripcionInput">Descripción:</label>
            <textarea
              id="descripcionInput"
              className="inputField"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="precioVentaInput">Precio Venta:</label>
            <input
              type="number"
              id="precioVentaInput"
              className="inputField"
              value={precioVenta}
              onChange={(e) => setPrecioVenta(Number(e.target.value))}
              required
            />
          </div>
          <div className="dadodebaja">
            <label htmlFor="dadoDeBajaCheckbox">
              Dado de Baja:
              <input
                type="checkbox"
                id="dadoDeBajaCheckbox"
                checked={dadoDeBaja}
                onChange={(e) => setDadoDeBaja(e.target.checked)}
              />
            </label>
          </div>
          <button type="button" onClick={handleEditarProducto}>
            Editar
          </button>
        </form>
      )}
    </div>
  );
};

export default EditarProducto;
