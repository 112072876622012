import React, { useState, useEffect,useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Context } from '../context/Context';
import {RespuestaAPIVencimiento,ProductVencimiento} from '../types/interfaces';
import '../styles/ProductosPorVencer.css'

const ProductosPorVencimiento: React.FC = () => {
  const {APIContext}=useContext(Context);
  const {consultaProductosVencimiento,validarToken,isAuthenticated,fechaHoy}=APIContext;
  const [products, setProducts] = useState<ProductVencimiento[]>([]);
  const [fecha, setFecha] = useState<string>(fechaHoy);
  const [filtroVencimiento, setFiltroVencimiento] = useState<number>(30); // Por defecto, mostrar productos que vencen en 30 días
  const navigate = useNavigate();

  const fetchProducts = async (filtroVencimiento:number) => {
    try {
      console.log(filtroVencimiento)
      let fechaDate=new Date(fecha)
      fechaDate.setDate(fechaDate.getDate()+filtroVencimiento)
      consultaProductosVencimiento().then((data:RespuestaAPIVencimiento) =>{
        if (data.status === 'ok') {
          const productosAPI: ProductVencimiento[] = data.result.Products
          const productosApiFiltrados=productosAPI.filter(p=>{
              let Fecha_Vencimiento:Date=new Date(p.Fecha_Vencimiento)
              return(
                Fecha_Vencimiento<fechaDate
              )
          })
          setProducts([...productosApiFiltrados]);
          console.log(productosApiFiltrados)
        }
      });
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Función para cambiar el filtro de vencimiento
  const cambiarFiltro = (dias: number) => {
    setFiltroVencimiento(dias);
  };

  useEffect(() => {
    // Aquí puedes realizar una llamada a tu backend para obtener la lista de productos
    // y luego actualizar el estado "products" con los datos recibidos
    try{
      // const respuesta:RespuestaAPI =await validarToken()
      validarToken()
      // await new Promise((resolve) => setTimeout(resolve, 5000));
        // console.log(respuesta)
        if(!isAuthenticated){
          navigate('/');
        }else{
          fetchProducts(filtroVencimiento);
        }
      
    }
    catch(e){
      console.log(e)
    }
    
  }, [isAuthenticated]);

  useEffect(() => {
    fetchProducts(filtroVencimiento); // Cargar productos al montar el componente
  }, [filtroVencimiento]); // Volver a cargar productos cuando cambie el filtro de vencimiento

  return (
    <div>
      <h2>Productos por Fecha de Vencimiento</h2>
      <div>
        <label>Filtrar por:</label>
        <select
          value={filtroVencimiento}
          onChange={(e) => cambiarFiltro(Number(e.target.value))}
        >
          <option value={30}>Próximo mes</option>
          <option value={90}>3 meses</option>
          <option value={180}>6 meses</option>
          <option value={365}>1 año</option>
        </select>
      </div>
      <ul className="producto-lista">
        {products.map((producto) => (
          <li key={producto.ID_Producto} className="producto-item">
          <div className="nombre">{producto.Nombre_Producto}</div>
          <div className="cantidad">Cantidad: {producto.Cantidad_Restante}</div>
          <div className="fecha-vencimiento">
            Fecha de vencimiento: {producto.Fecha_Vencimiento}
          </div>
        </li>
          // <li key={producto.ID_Producto}>
          //   {producto.Nombre_Producto} - Fecha de vencimiento: {producto.Fecha_Vencimiento}
          // </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductosPorVencimiento;
