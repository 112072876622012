import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Menu from './Menu';
import '../styles/menu.css';

const Layout = ({ children }) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Verificar si la ubicación actual es la página de inicio (login)
  const isHomePage = location.pathname === '/';

  // Condición para mostrar u ocultar el menú
  const showMenu = !isHomePage;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <React.Fragment>
      {showMenu && (
        <Menu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      )}
      <div className={`content ${isMenuOpen ? 'content-open' : ''}`}>
        {showMenu && (
          <button className="menu-toggle" onClick={toggleMenu}>
            <img src="menu-icon.png" alt="Toggle Menu" />
          </button>
        )}
        <div className="corner-icon" onClick={toggleMenu}>
          <img src="corner-icon.png" alt="Toggle Menu" />
        </div>
        {children}
      </div>
    </React.Fragment>
  );
};

export default Layout;


