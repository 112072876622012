import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from '../context/Context';
import '../styles/AgregarInventario.css'
import {ProductAPI,Product,RespuestaAPI,ProductCompraI} from '../types/interfaces';

interface ProductCompra {
  producto: string;
  cantidad: number;
  precio:number;
  vencimiento:string;
}

const AgregarInventario: React.FC = () => {
  const {APIContext}=useContext(Context);
  const {consultaProductos,APIProductos,validarToken,isAuthenticated,fechaHoy}=APIContext;
  console.log(fechaHoy)
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  // const [selectedProduct, setSelectedProduct] = useState<number>(0);
  const [filterBy, setFilterBy] = useState<string>("");
  const [quantity, setQuantity] = useState(0);
  const [fecha, setFecha] = useState<string>(fechaHoy);
  const [price, setPrice] = useState(0);
  const [expirationDate, setExpirationDate] = useState<string>("");
  const [cart, setCart] = useState<ProductCompraI[]>([]);
  const [cartCount, setCartCount] = useState(0);
  const [isCartOpen, setIsCartOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchProducts = async () => {
    try {
      consultaProductos().then((data:RespuestaAPI) =>{
        if (data.status === 'ok') {
          const productosAPI: ProductAPI[] = data.result.Products
          const tempProducts: Product[] = [];

          for (let d of productosAPI) {
            const hola: Product = {
              id: parseInt(d.ID_Producto),
              name: d.Nombre_Producto,
              description: d.Descripcion,
              price: parseFloat(d.Precio_Venta),
              stock: parseInt(d.Stock_Actual),
              quantity: 0
            }
            tempProducts.push(hola);
          }
          setProducts([...tempProducts]);
          setFilteredProducts([...tempProducts]);
          // console.log(products)
        }
      });
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleAddToCart = (product:Product) => {
    console.log("Fecha: "+expirationDate)
    const existingProduct = cart.find((item) => item.id === product.id);
    if(existingProduct){
      alert(`Ya se agregó ${existingProduct.name} al carrito`)
      return;
    }
    if (quantity > 0 && price > 0 && expirationDate!=="") {
      const newItem: ProductCompraI = {
        id:product.id,
        name: product.name,
        price: price,
        quantity: quantity,
        expiration:expirationDate
      };
      setCart([...cart, newItem]);
      // Update the cart count
      setCartCount(cartCount + 1);
      setQuantity(0);
      setPrice(0);
    }else{alert("Debe completar todos los campos de manera correcta")}
  };

  const handleRemoveFromCart = (productId: number) => {
    const updatedCart = cart.filter((item) => item.id !== productId);
    setCart(updatedCart);
    // Update the cart count
    setCartCount(cartCount - 1);
  };

  // Esta función manejará el evento beforeunload
  const handleBeforeUnload = (event:any) => {
    if (cartCount > 0) {
      event.preventDefault();
      event.returnValue = 'Tienes productos en tu carrito. ¿Realmente deseas salir de la página?';
    }
  };
  
  useEffect(() => {
    // Agrega el manejador de eventos beforeunload cuando el componente se monta
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Elimina el manejador de eventos beforeunload cuando el componente se desmonta
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [cartCount]);

  useEffect(() => {
    // Aquí puedes realizar una llamada a tu backend para obtener la lista de productos
    // y luego actualizar el estado "products" con los datos recibidos
    try{
      // const respuesta:RespuestaAPI =await validarToken()
      validarToken()
      // await new Promise((resolve) => setTimeout(resolve, 5000));
        // console.log(respuesta)
        if(!isAuthenticated){
          navigate('/');
        }else{
          fetchProducts();
        }
      
    }
    catch(e){
      console.log(e)
    }
    
  }, [isAuthenticated]);

  useEffect(() => {
    const filtered = products.filter((product) =>
      product.name.toLowerCase().includes(filterBy.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [products, filterBy]);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const RegistrarCompra = async () => {
    try {
      console.log(cart);
      const productosEnviar :ProductCompra[]=
      cart.map(p=>{
        const prod:ProductCompra={
          producto: p.name,
          cantidad: p.quantity,
          precio:p.price,
          vencimiento:p.expiration
        }
        return prod
      })
      console.log(productosEnviar)
      const body= {
        Estado:"AgregarInventario",
        Fecha_Compra:fecha,
        Productos:productosEnviar
      }
      APIProductos(body).then((data:RespuestaAPI) => {
        console.log(data)
        // Verificar si la solicitud fue exitosa
        if (data.status === 'ok') {
          alert("Productos registrados correctamente");
          setCart([])
          // Aquí podrías realizar alguna acción adicional, como limpiar los campos del formulario
          // o redireccionar a otra página
        } else {
          throw new Error("Error al agregar el producto");
        }});
      
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  return (
    <div>
      {/* Carrito */}
      <section>
        <div id="cart"
            onClick={toggleCart}>
          Cart
        </div>
        {cartCount>0 ? <div className="cart-count">{cartCount}</div>:null}
        <div className={isCartOpen ? "cart-items open" : "cart-items"}>
          <h2>Cart</h2>
          <ul>
                {cart.map((item) => (
                  <li key={item.id}>
                    <p>Producto: {products.find((p) => p.id === item.id)?.name}</p>
                    <p>Cantidad: {item.quantity}</p>
                    <p>Precio: S/{item.price}</p>
                    <button onClick={() => handleRemoveFromCart(item.id)}>
                      Quitar
                    </button>
                  </li>
                ))}
              </ul>
              <button onClick={() => RegistrarCompra()}>Registrar Compra</button>
        </div>
      </section>
      
      <h2>Agregar Inventario</h2>
      <div>
        <label htmlFor="fechaAddInventario">Fecha:</label>
        <input
          type="date"
          id="fechaAddInventario"
          value={fecha}
          onChange={(e) => setFecha(e.target.value)}
        />
      </div>
       

      {/* Filtro de productos */}
      <div>
        <label htmlFor="filterInput">Filtrar productos:</label>
        <input
          type="text"
          id="filterInput"
          value={filterBy}
          onChange={(e) => setFilterBy(e.target.value)}
        />
      </div>

      {/* Lista de productos */}
      <ul className="products">
        {filteredProducts.map((product) => (
          <li key={product.id}>
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <p>Cantidad: <input
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(Number(e.target.value))}
              /></p>
            <p>Precio: <input
                type="number"
                value={price}
                onChange={(e) => setPrice(Number(e.target.value))}
              /></p>
            <p>Fecha Vencimiento: <input
                type="date"
                value={expirationDate}
                onChange={(e) => setExpirationDate(String(e.target.value))}
              /></p>
            <div>
              <button onClick={() => handleAddToCart(product)}>Agregar</button>
            </div>
          </li>
        ))}
      </ul>

      
    </div>
  );
};

export default AgregarInventario;