import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import '../styles/AgregarProducto.css'
import { Context } from '../context/Context';
import {ProductAPI,RespuestaAPI} from '../types/interfaces';


const AgregarProducto: React.FC = () => {
  const {APIContext}=React.useContext(Context);
  const {consultaProductos,APIProductos,validarToken,isAuthenticated}=APIContext;

  const [nombre, setNombre] = useState<string>("");
  const [descripcion, setDescripcion] = useState<string>("");
  const [precioVenta, setPrecioVenta] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Aquí puedes realizar una llamada a tu backend para obtener la lista de productos
    // y luego actualizar el estado "products" con los datos recibidos
    try{
      // const respuesta:RespuestaAPI =await validarToken()
      validarToken()
      // await new Promise((resolve) => setTimeout(resolve, 5000));
        // console.log(respuesta)
        if(!isAuthenticated){
          navigate('/');
        }
      
    }
    catch(e){
      console.log(e)
    }
    
  }, [isAuthenticated]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validar si el nombre ya está registrado
    // Aquí deberías realizar la lógica de validación con tu backend
     // Reemplaza esta variable con la lógica de validación correspondiente
    const respuestaAPI=await consultaProductos()
    const productos:ProductAPI[]=respuestaAPI.result.Products
    console.log(productos);
    const nombreExistente = productos.find((item) => item.Nombre_Producto.toLowerCase().trim() === nombre.toLowerCase().trim());
    if (nombreExistente) {
      alert("El nombre ya está registrado");
      return;
    }

    // Realizar la solicitud al backend para agregar el nuevo producto
    try {
      const body= {
        Estado:"AgregarProducto",
        Nombre_Producto: nombre,
        Descripcion: descripcion,
        Precio_Venta: precioVenta,
      }
      console.log(body);
      APIProductos(body).then((data:RespuestaAPI) => {
      console.log(data)
      // Verificar si la solicitud fue exitosa
      if (data.status === 'ok') {
        alert("Producto agregado exitosamente");
        setNombre("");
        setDescripcion("");
        setPrecioVenta(0);
      } else {
        throw new Error("Error al agregar el producto");
      }});
      
    } catch (error) {
      console.error("Error al agregar el producto:", error);
      alert("Ocurrió un error al agregar el producto");
    }
  };

  return (
    <div className="form-container">
      <h2>Agregar Producto</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="nombreInput">Nombre:</label>
          <input
            type="text"
            id="nombreInput"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="descripcionInput">Descripción:</label>
          <textarea
            id="descripcionInput"
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="precioVentaInput">Precio Venta:</label>
          <input
            type="number"
            id="precioVentaInput"
            value={precioVenta}
            onChange={(e) => setPrecioVenta(Number(e.target.value))}
            required
          />
        </div>
        {/* <div>
          <label htmlFor="stockInput">Stock:</label>
          <input
            type="number"
            id="stockInput"
            value={stock}
            onChange={(e) => setStock(Number(e.target.value))}
            required
          />
        </div> */}
        <button type="submit">Agregar</button>
      </form>
    </div>
  );
};

export default AgregarProducto;
