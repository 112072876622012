import React, { createContext, useState } from 'react';
import useAPI from '../hooks/useAPI';

const Context = createContext();

function ContextProvider(props) {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const APIContext = useAPI();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const {state, addItem, deleteItem} = useRequerimiento();

  const login = () => {
    // Aquí puedes realizar la lógica de autenticación, como hacer una solicitud al backend para verificar las credenciales del usuario.
    // Si la autenticación es exitosa, puedes actualizar el estado de autenticación.
    setIsAuthenticated(true);
  };

  const logout = () => {
    // Aquí puedes realizar la lógica para cerrar la sesión del usuario, como eliminar el token de sesión o limpiar los datos de autenticación.
    // Después de cerrar la sesión, actualiza el estado de autenticación.
    setIsAuthenticated(false);
  };

  return (
    <Context.Provider value={{APIContext,isAuthenticated, login, logout}}>
      {props.children}
    </Context.Provider>
  );
}

export { Context, ContextProvider };
