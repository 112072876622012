import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={`sidebar ${isMenuOpen ? 'open' : ''}`}>
      <button className="menu-toggle" onClick={toggleMenu}>
        <img src="../Images/1.png" alt="Menu" />
      </button>
      <ul>
        <li>
          <NavLink exact to="/AgregarProducto" activeClassName="active">
            Agregar Producto
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/AgregarInventario" activeClassName="active">
            Agregar Inventario
          </NavLink>
        </li>
        <li>
          <NavLink to="/Venta" activeClassName="active">
            Venta
          </NavLink>
        </li>
        <li>
          <NavLink to="/EditarProducto" activeClassName="active">
            Editar Producto
          </NavLink>
        </li>
        <li>
          <NavLink to="/Vencimiento" activeClassName="active">
            Por Vencer
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
