import React from 'react';
import './App.css';
import { BrowserRouter,Routes, Route} from 'react-router-dom';
import { ContextProvider } from './context/Context';
import Layout from './containers/Layout';
import Inicio from './components/Inicio';
import AgregarProducto from './components/AgregarProducto';
import AgregarInventario from './components/AgregarInventario';
import Login from './components/Login';
import EditarProducto from './components/EditarProducto';
import ProductosPorVencimiento from './components/ProductosPorVencer';

function App() {
  return (
    <ContextProvider>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/Venta" element={<Inicio />} />
            <Route path="/AgregarProducto" element={<AgregarProducto />} />
            <Route path="/AgregarInventario" element={<AgregarInventario />} />
            <Route path="/EditarProducto" element={<EditarProducto />} />
            <Route path="/Vencimiento" element={<ProductosPorVencimiento />} />
            <Route path="*" element={<p>Esta ruta no existe: 404!</p>} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </ContextProvider>
    
  );
}

export default App;
