import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../context/Context';
import '../styles/login.css';
import {RespuestaAPI} from '../types/interfaces';

const Login: React.FC = () => {
  const {APIContext,login}=useContext(Context);
  const {validarUser}=APIContext;
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      validarUser(email, password)
      .then((respuesta:RespuestaAPI) => {
        if (respuesta.status === 'ok') {
          localStorage.setItem('token', respuesta.result.tokenGenerado);
          login();
          navigate('/Venta');
        } else {
          alert('Usuario o Contraseña Incorrecta');
        }
      });
    } catch (error) {
      // manejar el error
    }
  };

  return (
    <div className="container-form-login">
      <form className="form-login" onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" onClick={handleSubmit}>
          Iniciar sesión
        </button>
      </form>
    </div>
  );
};

export default Login