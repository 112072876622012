import React, { useState, useEffect, useContext  } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from '../context/Context';
import '../styles/Inicio.css'
import {RespuestaAPI,ProductAPI,Product} from '../types/interfaces';

interface ProductVenta {
  producto: string;
  cantidad: number;
}

const Inicio: React.FC = () => {
  const {APIContext}=useContext(Context);
  const {consultaProductos,validarToken,isAuthenticated,APIVentas,fechaHoy}=APIContext;
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [filterBy, setFilterBy] = useState<string>("");
  const [cart, setCart] = useState<Product[]>([]);
  const [cartCount, setCartCount] = useState(0);
  const [total, setTotal] = useState<number>(0);
  const [isCartOpen, setIsCartOpen] = useState<boolean>(false);
  const [flag, setFlag] = useState<number>(0);
  
  const navigate = useNavigate();

  const fetchProducts = async () => {
    try {
      consultaProductos()
        .then((data:RespuestaAPI) =>{
          if (data.status === 'ok') {
            // console.log(data)
            const productosAPI: ProductAPI[] = data.result.Products
            const tempProducts: Product[] = [];

            for (let d of productosAPI) {
              const hola: Product = {
                id: parseInt(d.ID_Producto),
                name: d.Nombre_Producto,
                description: d.Descripcion,
                price: parseFloat(d.Precio_Venta),
                stock: parseInt(d.Stock_Actual),
                quantity: 0
              }
              tempProducts.push(hola);
            }
            setProducts([...tempProducts]);
            // console.log(products)
          }
        });

    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    // Aquí puedes realizar una llamada a tu backend para obtener la lista de productos
    // y luego actualizar el estado "products" con los datos recibidos
    try{
      // const respuesta:RespuestaAPI =await validarToken()
      validarToken()
      // await new Promise((resolve) => setTimeout(resolve, 5000));
        // console.log(respuesta)
        if(!isAuthenticated){
          navigate('/');
        }else{
          fetchProducts();
        }
      
    }
    catch(e){
      console.log(e)
    }
    
  }, [isAuthenticated,flag]);


  useEffect(() => {
    // Aplicar filtrado cuando cambia el valor de "filterBy"
    const filtered = products.filter((product) =>
      product.name.toLowerCase().includes(filterBy.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [products, filterBy]);

  const addToCart = (product: Product) => {
    if(product.stock>0){
      const existingProduct = cart.find((item) => item.id === product.id);
      if (existingProduct) {
        console.log(existingProduct.quantity+" <= "+product.stock)
        if(existingProduct.quantity<product.stock){
          const updatedCart = cart.map((item) =>
            item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
          );
          setCart(updatedCart);
        }else{
          alert("No hay suficiente stock");
          return;
        }
      } else {
        const updatedCart = [...cart, { ...product, quantity: 1 }];
        setCart(updatedCart);
        // Update the cart count
        setCartCount(cartCount + 1);
      }
      const updatedTotal = total + product.price;
      setTotal(updatedTotal);
    }else{
      alert("No se puede agregar un producto que no tiene stock")
    }
  };

  const removeFromCart = (product: Product) => {
    const existingProduct = cart.find((item) => item.id === product.id);
    if (existingProduct) {
      if (existingProduct.quantity === 1) {
        const updatedCart = cart.filter((item) => item.id !== product.id);
        setCart(updatedCart);
        // Update the cart count
        setCartCount(cartCount - 1);
      } else {
        const updatedCart = cart.map((item) =>
          item.id === product.id ? { ...item, quantity: item.quantity - 1 } : item
        );
        setCart(updatedCart);
      }
      const updatedTotal = total - product.price;
      setTotal(updatedTotal);
    } else {
      alert("No se puede quitar un producto del carrito que no haya sido agregado previamente.");
    }
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const RegistrarVenta = async () => {
    try {
      console.log(cart);
      const productosEnviar: ProductVenta[] =
        cart.map(p => {
          const prod: ProductVenta = {
            producto: p.name,
            cantidad: p.quantity
          }
          return prod
        });
      console.log(productosEnviar);
      const body= {
        Fecha_Venta: fechaHoy,
        Comprador: 1,
        Productos: productosEnviar
      }
      APIVentas(body).then((data:RespuestaAPI)=>{
        if(data.status==="ok"){
          alert("Productos registrados correctamente");
          setCart([])
          setTotal(0)
          setFlag(flag+1)
        }
      })
      // const response = await fetch("http://localhost/Back-Farmacy/ventas", {
      //   method: 'POST',
      //   mode: 'cors',
      //   cache: 'no-cache',
      //   credentials: 'same-origin',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     // Otros headers si es necesario
      //   },
      //   redirect: 'follow',
      //   referrerPolicy: 'no-referrer',
      //   body: JSON.stringify({
      //     Fecha_Venta: "2023-06-15",
      //     Comprador: 1,
      //     Productos: productosEnviar
      //   }), // Convierte el objeto en una cadena JSON
      // })
      //   .then(resp => resp.json())
      //   .then(data => {
      //     if(data.status==="ok"){
      //       alert("Productos registrados correctamente");
      //     }
      //     setCart([])
      //     setTotal(0)
      //   });

    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };


  return (
    <div>
      {/* Carrito */}
      <section>
        <div
          id="cart"
          onClick={toggleCart}
          >
          Cart
        </div>
        {cartCount>0 ? <div className="cart-count">{cartCount}</div>:null}
        <div className={isCartOpen ? "cart-items open" : "cart-items"}>
          <h2>Cart</h2>
        <ul
          
        >
          {cart.map((product) => (
            <li key={product.id}>
              <h3>{product.name}</h3>
              <p>Precio: S/{product.price}</p>
              <p>Cantidad: {product.quantity}</p>
            </li>
          ))}
        </ul>
        <p>Total: S/{total.toFixed(2)}</p>
        <button onClick={RegistrarVenta}>Registrar Venta</button>

        </div>
      </section>

      <h1>Product Management</h1>
      {/* Filtros */}
      <div className="filters">
        <label htmlFor="filterInput">Filter by name:</label>
        <input
          type="text"
          id="filterInput"
          value={filterBy}
          onChange={(e) => setFilterBy(e.target.value)}
        />
      </div>

      {/* Lista de productos */}
      <ul className="products">
        {filteredProducts.map((product) => (
          <li key={product.id} className="product">
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <p>Precio: S/{product.price}</p>
            <p>Stock: {product.stock>0 ? product.stock:"Sin stock"}</p>
            <button className="add-button" onClick={() => addToCart(product)}>Añadir</button>
            <button className="del-button" onClick={() => removeFromCart(product)}>Eliminar</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Inicio;
